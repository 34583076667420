import React, { useRef } from "react";
import { ChartConfiguration } from "chart.js";
import "chartjs-adapter-moment";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const BarChart = ({ data }: { data: ChartConfiguration }) => {
  const ref = useRef<Chart>();
  React.useLayoutEffect(() => {
    if (ref.current) {
      ref.current.destroy();
    }
    ref.current = new Chart("myChart", data);
  }, [data]);
  return (
    <div>
      <canvas id="myChart" />
    </div>
  );
};

export default React.memo(BarChart);
