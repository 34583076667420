import React, { FormEvent, useCallback } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { callApi } from "./config";
import { Link, useLocation } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        @BitcloutSignal
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function VerifyPost() {
  const classes = useStyles();
  const postRef = React.useRef<any>();
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const location = useLocation();

  const onSubmit = useCallback(async (e?: FormEvent) => {
    e?.preventDefault();
    setError("");
    setSuccess(false);
    const postUri = postRef.current.value as string;
    if (postUri.startsWith("https://bitclout.com/posts/")) {
      try {
        const response = await callApi("/verify-post", { postUri });
        if (response.status === "success") {
          setSuccess(true);
          return;
        }
      } catch (e) {}
    }
    setError("Couldn't verify your post, try again");
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {location.search.includes("registered") && (
          <Alert severity="success" style={{ margin: "20px 0" }}>
            <h2>Your account has been registered!</h2>
            <br />
            <h3>
              Your password: <b>{atob(localStorage.token).split(":")[1]}</b>
            </h3>
          </Alert>
        )}
        <Typography
          component="h1"
          variant="h5"
          gutterBottom
          style={{ marginBottom: 40 }}
        >
          Verify your account
        </Typography>
        <ol className="list-decimal" style={{ marginLeft: 20 }}>
          <li>
            Go to{" "}
            <a target="_blank" rel="noreferrer" href="https://bitclout.com">
              bitclout.com
            </a>
          </li>
          <li>
            Create a post containing: Setting up my @bitcloutsignal account 🚀
          </li>
          <li>Copy the post's url eg https://bitclout.com/posts/d581...</li>
          <li>Paste below and verify!</li>
          <li>Voila!</li>
        </ol>
        {error && (
          <Typography component="h3" variant="h5">
            {error}
          </Typography>
        )}
        {success ? (
          <>
            <Alert severity="success" style={{ margin: "20px 0" }}>
              <p>
                Nice! We've successfully verified your account, you can now use
                our Telegram bot and web tools!
              </p>
              <br />
              <p>
                Activate our bespoke{" "}
                <a href="https://t.me/bitcloutsignalbot">Telegram bot</a>
              </p>
              <p>
                Try our <Link to="/portfolio">portfolio tool</Link>
              </p>
            </Alert>
          </>
        ) : (
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={postRef}
              required
              fullWidth
              id="post"
              placeholder="https://bitclout.com/posts/123..."
              label="Post URL"
              name="post"
              helperText={error}
              error={!!error}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Verify
            </Button>
            <Grid container>
              <Grid item>
                <Link to="/register">Don't have an account? Register here</Link>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
