import { useMemo } from "react";
import { useHistory } from "react-router";
import Graph from "react-vis-network-graph";

interface Transfer {
  _id: string;
  sender: string;
  receiver: string;
  senderUsername: string;
  receiverUsername: string;
  amount: number;
  timestamp: string;
}

export default function Network({ transfers }: { transfers: Transfer[] }) {
  const history = useHistory();
  const graph = useMemo(() => {
    if (!transfers) {
      return null;
    }
    const nodes: { id: string; username: string }[] = [];
    const edges: { from: string; to: string; value: number }[] = [];

    transfers.forEach((t) => {
      if (!nodes.some((u) => u.id === t.sender)) {
        nodes.push({ id: t.sender, username: t.senderUsername });
      }
      if (!nodes.some((u) => u.id === t.receiver)) {
        nodes.push({ id: t.receiver, username: t.receiverUsername });
      }
      edges.push({ from: t.sender, to: t.receiver, value: t.amount });
    });

    const graph = {
      nodes: nodes.map((n) => ({
        id: n.id,
        label: n.username || n.id.substr(0, 5),
      })),
      edges,
    };
    return graph;
  }, [transfers]);

  if (!transfers) {
    return <h2>Loading...</h2>;
  }

  const options = {
    layout: {
      hierarchical: false,
    },
    edges: {
      color: "#1D243A99",
    },
    height: "500px",
  };

  const events = {
    doubleClick: function (event) {
      history.push("/transfers/" + event.nodes[0]);
    },
  };

  return (
    <Graph
      key={JSON.stringify(graph)}
      graph={graph}
      options={options}
      events={events}
    />
  );
}
