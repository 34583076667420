import React, { useContext, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Container, Tab, Tabs, Typography } from "@material-ui/core";
import { AppContext, callApi, getHistoryLink } from "./config";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Alert from "@material-ui/lab/Alert";

interface Data {
  username: string;
  change: number;
  startPrice: number;
  endPrice: number;
  diffLocked: number;
  key: string;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    ellipsis: {
      maxWidth: 250,
      textOverflow: "ellipsis",
      display: "inline-block",
      overflow: "hidden",
    },
    buy: {
      color: "green",
    },
    sell: {
      color: "red",
    },
  })
);

export default function TopCreators() {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [hours, setHours] = useState(24);
  const [data, setData] = React.useState<{
    users: Data[];
  }>();

  const { usd, timestamp } = useContext(AppContext);
  const getUSD = (bitclout: number) => formatter.format(usd * bitclout);

  const fetchData = React.useCallback(async () => {
    setError("");
    setData(undefined);
    try {
      const response = await callApi("/public/stats/topCreators" + hours);
      if (response.length) {
        setData({
          users: response,
        });
      }
    } catch (e) {}
  }, [hours]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (error) {
    return (
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    );
  }

  if (!data) {
    return <h2>Loading...</h2>;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Alert severity="info">
          Based on confirmed transactions, latest sync:{" "}
          {timestamp ? new Date(timestamp * 1000).toString() : "~1 h ago"}.
          Using ~$
          {usd.toFixed(2)} per coin.
        </Alert>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            style={{ marginTop: 20 }}
          >
            Top Creators
          </Typography>
          <Typography variant="body1" gutterBottom>
            Showing the top creators based on TVL increase. Which creators have
            received most $$$ the last hours.
          </Typography>
          <Tabs
            value={hours}
            onChange={(e, v) => setHours(v)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab value={6} label="6h" />
            <Tab value={24} label="24h" />
            <Tab value={24 * 7} label="7 days" />
          </Tabs>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Creator</TableCell>
                  <TableCell align="right">TVL Increase</TableCell>
                  <TableCell align="right">Coin Price</TableCell>
                  <TableCell align="right">Coin Price Change</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.users.map((u) => (
                  <TableRow key={u.key}>
                    <TableCell component="th" scope="row">
                      <span className={classes.ellipsis}>
                        {getHistoryLink(u.username, u.key)}
                      </span>
                    </TableCell>
                    <TableCell align="right">{getUSD(u.diffLocked)}</TableCell>
                    <TableCell align="right">{getUSD(u.endPrice)}</TableCell>
                    <TableCell align="right">
                      {u.change > 0 ? (u.change * 100).toFixed(2) : "∞"}%
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Paper>
    </div>
  );
}
