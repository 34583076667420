import { Launch } from "@material-ui/icons";
import { createContext } from "react";
import { Link } from "react-router-dom";
import { Log } from "./types";

export const apiUrl =
  window.location.hostname === "localhost"
    ? "http://localhost:3004"
    : "https://api.bitcloutsignal.com";

export const callApi = async (path: string, body?: object) => {
  const token = window.localStorage.token;
  if (!token && path.startsWith("/portfolio")) {
    throw Error("401");
  }
  const response = await fetch(apiUrl + path, {
    method: body ? "POST" : "GET",
    body: body && JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + token,
    },
  });
  const data = await response.json();

  if (response.status !== 200) {
    throw Error("" + response.status);
  }
  return data;
};

export const getPrice = async () => {
  const { usd } = await callApi("/public/bitclout-usd");
  return usd;
};

export const AppContext = createContext({ usd: 156, timestamp: 0 });

export const getSimpleLink = (usernameOrKey: string) => {
  const username = usernameOrKey.startsWith("BC1") ? undefined : usernameOrKey;
  return getHistoryLink(username, usernameOrKey);
};

export const getHistoryLink = (username: string | undefined, key: string) =>
  username ? (
    <>
      <Link to={`/history/${username}`}>@{username}</Link>{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://bitclout.com/u/${username}`}
      >
        <Launch style={{ fontSize: 12 }} />
      </a>
    </>
  ) : (
    <span
      style={{
        maxWidth: 150,
        textOverflow: "ellipsis",
        display: "inline-block",
        overflow: "hidden",
      }}
    >
      {key}
    </span>
  );

export const getTransferLink = (username: string | undefined, key: string) => (
  <>
    <Link to={`/transfers/${username || key}`}>
      <span
        style={{
          maxWidth: 150,
          textOverflow: "ellipsis",
          display: "inline-block",
          overflow: "hidden",
        }}
      >
        {username ? `@${username}` : key}
      </span>
    </Link>{" "}
    {username && (
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://bitclout.com/u/${username}`}
      >
        <Launch style={{ fontSize: 12 }} />
      </a>
    )}
  </>
);

export const toPrecision = (v: number | undefined, p = 1) => {
  if (!v) {
    return 0;
  }
  const rounded = v;
  if (Math.abs(rounded) > 100) {
    return rounded.toFixed(0);
  }
  if (Math.abs(rounded) > 1) {
    return rounded.toFixed(p);
  }
  return rounded.toPrecision(p);
};

export const SalomonFixBlockHeight = 15270;
export const BitCloutFounderRewardBlockHeight = 21869;

// - (((dB + m*RR*s^(1/RR))/(m*RR)))^RR-s
// - where:
//     dB = bigDeltaBitClout,
//     m = params.CreatorCoinSlope
//     RR = params.CreatorCoinReserveRatio
//     s = bigCurrentCreatorCoinSupply

export const RR = 0.3333333;
const magicNumber = 999999.9;

const getInitialCirculation = (locked: number) => {
  return Math.floor(Math.pow(locked / magicNumber, RR) * 1e9);
};

export const getCoinsFromBuying = (
  postFees: number,
  locked: number,
  circulation: number
) => {
  if (locked === 0 || circulation === 0) {
    // Initial buy lets use this
    return getInitialCirculation(postFees);
  }
  return Math.floor(circulation * (Math.pow(1 + postFees / locked, RR) - 1));
};

export const getBitCloutFromSelling = (
  coinSold: number,
  locked: number,
  circulation: number
) => {
  if (circulation === 0) {
    return locked;
  }
  let bitclouts = Math.floor(
    locked * (1 - Math.pow(1 - coinSold / circulation, 1 / RR))
  );
  if (bitclouts > locked) {
    bitclouts = locked;
  }
  return bitclouts;
};

// All in NON-nanos
export const getCoinPrice = (locked: number, circulation: number) => {
  if (!locked || !circulation) {
    return 0;
  }
  const ret = Math.floor((locked / circulation / RR) * 1e9) * 1e-9;
  return ret;
};

export const getPriceChange = (log: Log) => {
  const lockedPre = log.locked - (log.bitclouts || 0);
  const circulationPre = log.circulation - log.coins;

  const pricePre = getCoinPrice(lockedPre, circulationPre);
  const price = getCoinPrice(log.locked, log.circulation);
  return ((price - pricePre) / pricePre) * 100;
};
