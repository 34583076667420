/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

const botSpan = (limit) => (
  <span>
    Our <a href="https://t.me/bitcloutsignalbot">Telegram bot</a>, get instant
    alerts from the people/coins you want. Follow/track limit: <b>{limit}</b>.
  </span>
);

const tiers = [
  {
    name: "Free",
    description: "All the basics for starting a new business",
    includedFeatures: [
      <span>
        See anyone's <Link to="/history/bitcloutsignal">coin history</Link>
      </span>,
      // <Link to="/diamonds">💎 Diamond Billboard</Link>,
      <Link to="/top-transactions">Top Transactions</Link>,
      <Link to="/top-transfers">Top Transfers</Link>,
      <Link to="/rug-pulls">Top Rug Pulls</Link>,
      <Link to="/stats">Stats and Graphs</Link>,
    ],
  },
  {
    name: "Verified (Free)",
    link: "/register",
    linkText: "Sign up for free",
    priceMonthly: 0,
    description:
      "Level up for free! Simply verify your account and you get access to our Portfolio tool and Bot 🤖",
    includedFeatures: [
      <span>
        <Link to="/portfolio">Portfolio Tool</Link>, analyze your growth on a
        coin level
      </span>,
      botSpan(3),
      <span>
        <Link to="/top-transfers">Transfer Explorer</Link> lets you follow the
        momney, see all transfers in/out from any wallet
      </span>,
    ],
  },
  {
    name: "$500 HODL",
    link: "https://bitclout.com/u/bitcloutsignal",
    linkText: "Invest now",
    description:
      "HODL $500 to access our Portfolio Tool, see your growth on a coin level",
    includedFeatures: [botSpan(10)],
  },
  {
    name: "Top 10 HODLers",
    link: "https://bitclout.com/u/bitcloutsignal",
    linkText: "Invest now",
    description: "Exclusive tools and access to our top 10 HODLers",
    includedFeatures: [
      "See any user/wallet's Portfolio performance 👀",
      "Extra bot followers",
      "Private Telegram access to ask data/analysis related questions",
      "And more",
    ],
  },
];

export default function Offering() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-extrabold text-gray-900">
                  {tier.name}
                </h2>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                {tier.link && (
                  <a
                    href={tier.link}
                    target={tier.link?.includes("http") ? "_blank" : undefined}
                    rel="noreferrer"
                    style={{
                      backgroundColor: "#1B233C",
                      textDecoration: "none",
                    }}
                    className="mt-8 block w-full ounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >
                    {tier.linkText}
                  </a>
                )}
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  What's included
                </h3>
                <ul className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature, i) => (
                    <li key={i} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
