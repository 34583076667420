import { Typography } from "@material-ui/core";
import React from "react";
import BarChart from "./BarChart";
import { apiUrl } from "./config";

type Day = {
  _id: string;
  buy: number;
  sell: number;
};

const VolumeStats = () => {
  const [days, setDays] = React.useState<Day[]>([]);
  const fetchStuff = React.useCallback(async () => {
    const data: Day[] = await fetch(apiUrl + "/public/stats/volume").then((r) =>
      r.json()
    );
    // Remove todays
    data.pop();
    setDays(data);
  }, []);
  React.useEffect(() => {
    fetchStuff();
  }, [fetchStuff]);

  const graphData = {
    type: "bar" as "bar",
    data: {
      labels: days.map((d) => d._id),
      datasets: [
        {
          label: "Bought",
          data: days.map((d) => d.buy),
          borderWidth: 1,
          backgroundColor: "#67D96Ccc",
        },
        {
          label: "Sold",
          data: days.map((d) => -d.sell),
          borderWidth: 1,
          backgroundColor: "#ED7470cc",
        },
        {
          label: "Volume",
          data: days.map((d) => d.sell + d.buy),
          borderWidth: 2,
          type: "line",
          borderColor: "#9ABDEF",
          backgroundColor: "#9ABDEF",
          pointRadius: 1,
          tension: 0.4,
        },
      ],
    },
    options: {
      scales: {
        x: {
          stacked: true,
          type: "time",
          time: {
            tooltipFormat: "YYYY-MM-DD",
            unit: "day",
          },
        } as any,
      },
    },
  };
  return (
    <>
      <div
        style={{
          maxWidth: "90%",
          width: 1000,
          margin: "40px auto",
        }}
      >
        <Typography variant="h3" gutterBottom>
          Creator Coin Volume
        </Typography>
        <Typography variant="body1" gutterBottom>
          Sales volume in BitClouts.
        </Typography>
        {days.length && <BarChart data={graphData as any} />}
      </div>
    </>
  );
};

export default React.memo(VolumeStats);
