/* This example requires Tailwind CSS v2.0+ */
import Offering from "./Offering";
import { Link } from "react-router-dom";

export default function NewHome() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <img
            alt="@bitcloutsignal"
            src="/logo.png"
            width="100"
            style={{ margin: "auto" }}
          />
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            BitClout Analytics 📈
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            The best and latest tools to analyze and improve your Bitclout game
            🚀
          </p>
        </div>
        {localStorage.publicKey && (
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/portfolio"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-900 hover:bg-indigo-700"
              >
                Portfolio Analyzer
              </Link>
            </div>
          </div>
        )}
        {!localStorage.publicKey && (
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/register"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-900 hover:bg-indigo-700"
              >
                Sign up
              </Link>
            </div>
            <div className="ml-3 inline-flex">
              <Link
                to="/login"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
              >
                Already have an account?
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white">
        <Offering />
      </div>
      {/* <VolumeStats /> */}
    </div>
  );
}
