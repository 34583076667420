import React, { useContext, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Container, Grid, Typography } from "@material-ui/core";
import { AppContext, callApi, getTransferLink, toPrecision } from "./config";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router";
import { Profile } from "./types";
import Network from "./Network";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    ellipsis: {
      maxWidth: 250,
      textOverflow: "ellipsis",
      display: "inline-block",
      overflow: "hidden",
    },
    buy: {
      color: "green",
    },
    sell: {
      color: "red",
    },
  })
);

interface Transfer {
  _id: string;
  sender: string;
  receiver: string;
  senderUsername: string;
  receiverUsername: string;
  amount: number;
  timestamp: string;
}

export default function Transfers() {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [data, setData] = React.useState<{
    transfers: Transfer[];
    profile: Profile;
  }>();
  const history = useHistory();
  const { username } = useParams<any>();
  const { usd, timestamp } = useContext(AppContext);
  const getUSD = (bitclout: number) => formatter.format(usd * bitclout);

  const fetchData = React.useCallback(async () => {
    setError("");
    setData(undefined);
    try {
      const response = await callApi("/transfers/" + username);
      if (response?.transfers) {
        setData(response);
      }
    } catch (e) {
      if (e.message === "401") {
        history.replace("/login");
      } else if (e.message === "403") {
        setError(
          "To dig deeper and view transfers made by any account you need to HODL $500 of @bitcloutsignal"
        );
        return;
      } else if (e.message === "404") {
        setError("User/key not found");
        return;
      }
    }
  }, [username, history]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (error) {
    return (
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    );
  }

  if (!data) {
    return <h2>Loading...</h2>;
  }

  const key = data.profile.key;

  let totalIn = 0;
  let totalOut = 0;

  data.transfers.forEach((t) => {
    if (t.sender === key) {
      totalOut += t.amount;
    } else if (t.receiver === key) {
      totalIn += t.amount;
    }
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Alert severity="info">
          Based on confirmed transactions, latest sync:{" "}
          {timestamp ? new Date(timestamp * 1000).toString() : "~1 h ago"}.
          Using ~$
          {usd.toFixed(2)} per coin.
        </Alert>
        <Network transfers={data.transfers} />
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            style={{ marginTop: 20 }}
          >
            Transfers to/from{" "}
            {data.profile.username
              ? `@${data.profile.username}`
              : data.profile.key}
          </Typography>
          {data.transfers.length === 1000 && (
            <Alert severity="warning">
              Showing the 1000 most recent transfers. Totals are not correct
            </Alert>
          )}
          <Grid
            container
            spacing={4}
            style={{ textAlign: "center", margin: "20px 0" }}
          >
            <Grid item md={4} xs={12}>
              <Paper elevation={3} style={{ padding: "20px 0" }}>
                <Typography variant="body1">Bitclout in</Typography>
                <Typography variant="h4">{toPrecision(totalIn)}</Typography>
              </Paper>
            </Grid>
            <Grid item md={4} xs={12}>
              <Paper elevation={3} style={{ padding: "20px 0" }}>
                <Typography variant="body1">Bitclout out</Typography>
                <Typography variant="h4">{toPrecision(totalOut)}</Typography>
              </Paper>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Timestamp</TableCell>
                  <TableCell>Sender</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Receiver</TableCell>
                  <TableCell align="right">Bitclout</TableCell>
                  <TableCell align="right">~USD</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.transfers.map((transfer) => (
                  <TableRow key={transfer._id}>
                    <TableCell>
                      {moment(transfer.timestamp).format("YYYY-MM-DD HH:mm")}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight:
                          key === transfer.sender ? "bold" : undefined,
                      }}
                    >
                      {getTransferLink(
                        transfer.senderUsername,
                        transfer.sender
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      ➡️
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight:
                          key === transfer.receiver ? "bold" : undefined,
                      }}
                    >
                      {getTransferLink(
                        transfer.receiverUsername,
                        transfer.receiver
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {toPrecision(transfer.amount)}
                    </TableCell>
                    <TableCell align="right">
                      ~{getUSD(transfer.amount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Paper>
    </div>
  );
}
