import React, { FormEvent } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import UILink from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { apiUrl } from "./config";
import { Link, useHistory } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <UILink color="inherit" href="https://bitcloutsignal.com">
        @BitcloutSignal
      </UILink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const keyRef = React.useRef<any>();
  const emailRef = React.useRef<any>();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError("");
    const key = keyRef.current.value as string;
    const email = emailRef.current.value as string;
    if (key) {
      try {
        setLoading(true);
        const data = await fetch(apiUrl + "/auth/create-user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ key, email }),
        }).then((r) => r.json());
        if (data?.message === "Created") {
          window.localStorage.publicKey = key;
          window.localStorage.token = data.token;
          history.push("/verify?registered=1");
        } else {
          setError(data?.message || "Failed");
        }
        setLoading(false);
        return;
      } catch (e) {
        console.warn(e);
      }
    }
    setLoading(false);
    setError("Incorrect data, try again");
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" gutterBottom>
          Sign up
        </Typography>
        <Typography variant="body1" paragraph>
          Enter your public key on BitClout below to get started.
        </Typography>

        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            inputRef={keyRef}
            required
            fullWidth
            id="key"
            placeholder="BC1...."
            label="Public Key"
            name="key"
            helperText={error}
            error={!!error}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            inputRef={emailRef}
            type="email"
            placeholder="bit@clout.com"
            fullWidth
            name="email"
            label="Email (only to send login link)"
            id="email"
            error={!!error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
          >
            {loading ? "Submitting..." : "Register"}
          </Button>
        </form>
      </div>
      <p style={{ textAlign: "center" }}>
        <Link to="/">Back home</Link>
      </p>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
