import React, { useContext, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  AppContext,
  callApi,
  getCoinPrice,
  getPriceChange,
  toPrecision,
} from "./config";
import moment from "moment";
import { Log, Profile } from "./types";
import Alert from "@material-ui/lab/Alert";
import MyTable, { Header } from "./MyTable";
import BarChart from "./BarChart";

interface Data {
  username: string;
  coins: number;
  in: number;
  out: number;
  value: number;
  received: number;
  sent: number;
  maxCoins: number;
  daysHold: number;
  diff: number;
  change: number;
  key: string;
  sellPrice: number;
  coinShare: number;
  circulation: number;
  locked: number;
  price: number;
  days: number;
}

interface LogExtended extends Log {
  _id: string;
  creatorUsername: string;
  transactorUsername: string;
  receiverUsername?: string;
  locked: number;
  bitclouts: number;
  row: Data;
  coins: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    ellipsis: {
      maxWidth: 250,
      textOverflow: "ellipsis",
      display: "inline-block",
      overflow: "hidden",
    },
    buy: {
      color: "green",
    },
    sell: {
      color: "red",
    },
  })
);

export default function Portfolio() {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [minChange, setMinChange] = useState(0);
  const [data, setData] =
    React.useState<{
      logs: LogExtended[];
      rows: Data[];
      profile: Profile;
    }>();

  const { usd, timestamp } = useContext(AppContext);

  const { username } = useParams<any>();

  const fetchData = React.useCallback(async () => {
    const param = username || window.localStorage.publicKey;
    if (!param) {
      setError("Inccorrect username/key");
      return;
    }
    setError("");
    try {
      const response = await callApi("/history/" + param);

      if (response?.logs) {
        setData({
          logs: response.logs,
          rows: response.rows,
          profile: response.profile,
        });
      }
    } catch (e) {
      if (e.message === "401") {
        history.replace("/login");
      } else if (e.message === "403") {
        setError(
          "You only have access to your own data. Contact @bitcloutsignal if you want to see more 😏"
        );
        return;
      } else if (e.message === "404") {
        setError("User/key not found");
        return;
      }
    }
  }, [username, history]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const graphData = React.useMemo(() => {
    if (!data) {
      return null;
    }
    const map = {} as {
      [day: string]: { in: number; out: number; price: number };
    };
    data.logs
      .filter((l) => l.action !== "transfer")
      .forEach((l) => {
        const day = moment(l.timestamp * 1000).format("YYYY-MM-DD");
        map[day] = map[day] || { in: 0, out: 0, price: 0 };
        map[day].price = getCoinPrice(l.locked, l.circulation) * usd;
        if (l.action === "buy") {
          map[day].in += l.bitclouts;
        } else if (l.action === "sell") {
          map[day].out += l.bitclouts;
        }
      });
    const labels = Object.keys(map) as string[];
    const values = Object.values(map);
    return {
      type: "bar" as "bar",
      data: {
        labels,
        datasets: [
          {
            label: "Bought",
            data: values.map((d) => d.in),
            backgroundColor: "#67D96C55",
            yAxisID: "bitclout",
          },
          {
            label: "Sold",
            data: values.map((d) => d.out),
            backgroundColor: "#ED747055",
            yAxisID: "bitclout",
          },
          {
            label: "Price",
            data: values.map((d) => d.price),
            borderWidth: 2,
            type: "line" as any,
            borderColor: "#9ABDEF",
            backgroundColor: "#9ABDEF",
            pointRadius: 2,
            stepped: true,
            yAxisID: "price",
          },
        ],
      },
      options: {
        scales: {
          bitclout: {
            title: { text: "Bought/Sold (bclt)", display: true },
            type: "linear",
            position: "left",
          },
          price: {
            title: { text: "Price USD", display: true },
            type: "linear",
            position: "right",
          },
          x: {
            stacked: true,
            type: "time",
            time: {
              tooltipFormat: "YYYY-MM-DD",
              unit: "day",
            },
          } as any,
        },
      },
    };
  }, [data, usd]);

  const rows = React.useMemo(
    () =>
      data &&
      data.logs
        .filter((l) => l.action !== "transfer")
        .map((log) => {
          return {
            timestamp: moment(log.timestamp * 1000).format("YYYY-MM-DD HH:mm"),
            transactor: log.transactorUsername || log.transactor,
            action: {
              buy: "Bought 📈",
              sell: "Sold 📉",
            }[log.action],
            coins: Math.abs(log.coins - (log.fr || 0)),
            amount: usd * Math.abs(log.bitclouts),
            fr: log.action === "buy" ? Math.abs(log.fr || 0) : 0,
            circulation: log.circulation,
            coinPrice: usd * getCoinPrice(log.locked, log.circulation),
            change: getPriceChange(log),
          };
        })
        .reverse(),
    [data, usd]
  );

  const hodlRows = React.useMemo(
    () =>
      data &&
      data.rows.map((d) => {
        const coins = d.coins < 0.00001 ? 0 : d.coins;
        const circulation = d.circulation;
        const price = usd * getCoinPrice(d.locked, d.circulation);

        // const coinsBough = d.maxCoins;
        // const coinsSold = d.maxCoins - coins;
        // const buyPrice = (usd * d.in) / coinsBough;
        // const soldPrice = coinsSold > 0.0001 ? (usd * d.out) / coinsSold : 0;
        return {
          in: usd * d.in,
          out: usd * d.out,
          value: usd * d.value,
          coins,
          transfers: `+${toPrecision(d.received)}/-${toPrecision(d.sent)}`,
          // buyPrice,
          // soldPrice,
          transactor: d.username || d.key,
          diff: d.value - d.in + d.out,
          change: ((d.value - d.in + d.out) / d.in) * 100,
          price,
          coinShare: circulation ? (coins / circulation) * 100 : 0,
          days: d.daysHold,
        };
      }),
    [data, usd]
  );

  const transfers = React.useMemo(
    () =>
      data &&
      data.logs
        .filter((l) => l.action === "transfer")
        .map((log) => {
          return {
            timestamp: moment(log.timestamp * 1000).format("YYYY-MM-DD HH:mm"),
            transactor: log.transactorUsername || log.transactor,
            receiver: log.receiverUsername || log.receiver || "",
            coins: log.coins || 0,
          };
        }),
    [data]
  );

  if (error) {
    return (
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    );
  }

  if (!rows || !hodlRows) {
    return <h2>Loading...</h2>;
  }

  const headers: Header<typeof rows[0]>[] = [
    {
      label: "Date",
      id: "timestamp",
    },
    {
      label: "Transactor",
      id: "transactor",
      type: "user",
      sort: false,
    },
    {
      label: "Action",
      id: "action",
    },
    {
      label: "Amount",
      id: "amount",
      type: "usd",
    },
    {
      label: "Coins",
      id: "coins",
      type: "number",
    },
    {
      label: "FR",
      id: "fr",
      type: "number",
    },
    {
      label: "Circulation",
      id: "circulation",
      type: "number",
    },
    {
      label: "Coin price",
      id: "coinPrice",
      type: "usd",
    },
    {
      label: "Change",
      id: "change",
      type: "percent",
      colored: true,
    },
  ];

  const hodlHeaders: Header<typeof hodlRows[0]>[] = [
    {
      label: "Transactor",
      id: "transactor",
      type: "user",
      sort: false,
    },
    {
      label: "HODL Days",
      id: "days",
      type: "number",
    },
    {
      label: "Coins",
      id: "coins",
      type: "number",
    },
    {
      label: "Transfers",
      id: "transfers",
    },
    {
      label: "Coin Share",
      id: "coinShare",
      type: "percent",
    },
    {
      label: "Invested",
      id: "in",
      type: "usd",
    },
    // {
    //   label: "Avg. Buy Price",
    //   id: "buyPrice",
    //   type: "usd",
    // },
    {
      label: "Sold",
      id: "out",
      type: "usd",
    },
    // {
    //   label: "Avg. Sell Price",
    //   id: "soldPrice",
    //   type: "usd",
    // },
    {
      label: "Value",
      id: "value",
      type: "usd",
    },
  ];

  const transferHeaders: Header<{
    timestamp: string;
    transactor: string;
    receiver: string;
    coins: number;
  }>[] = [
    {
      label: "Date",
      id: "timestamp",
    },
    {
      label: "Transactor",
      id: "transactor",
      type: "user",
      sort: false,
    },
    {
      label: "Receiver",
      id: "receiver",
      type: "user",
      sort: false,
    },
    {
      label: "Coins",
      id: "coins",
      type: "number",
    },
  ];

  return (
    <div className={classes.root}>
      <Alert severity="info">
        Based on confirmed transactions, latest sync:{" "}
        {timestamp ? new Date(timestamp * 1000).toString() : "~1 h ago"}. Using
        ~$
        {usd.toFixed(2)} per coin.
      </Alert>
      <Container maxWidth="lg">
        {data && (
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            style={{ marginTop: 20 }}
          >
            {data.profile.username
              ? `@${data.profile.username}`
              : data.profile.key}
          </Typography>
        )}
        {graphData && <BarChart data={graphData as any} />}

        <Tabs
          value={tabIndex}
          onChange={(e, v) => setTabIndex(v)}
          indicatorColor="primary"
          textColor="primary"
          style={{ margin: "20px 0" }}
          centered
        >
          <Tab label="History Log" />
          <Tab label="HODLer View" />
          <Tab label="Transfers Log" />
        </Tabs>
        {tabIndex === 1 && (
          <MyTable
            headers={hodlHeaders}
            rows={hodlRows.slice(0, 1000)}
            defaultSortBy="value"
          />
        )}
        {tabIndex === 0 && (
          <>
            <FormControl>
              Only show changes &gt;{minChange}%
              <Select
                labelId="demo-simple-select-label"
                value={minChange}
                onChange={(e) =>
                  setMinChange(parseInt(e.target.value as string))
                }
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={5}>5%</MenuItem>
                <MenuItem value={10}>10%</MenuItem>
                <MenuItem value={50}>50%</MenuItem>
              </Select>
            </FormControl>
            <MyTable
              headers={headers}
              rows={rows.filter((r) => Math.abs(r.change) >= minChange)}
            />
          </>
        )}

        {tabIndex === 2 && transfers && (
          <MyTable headers={transferHeaders} rows={transfers} />
        )}
      </Container>
    </div>
  );
}
