import React, { FormEvent, useCallback } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { callApi } from "./config";
import { useHistory } from "react-router";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://bitcloutsignal.com">
        @BitcloutSignal
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const keyRef = React.useRef<any>();
  const passwordRef = React.useRef<any>();
  const history = useHistory();
  const [error, setError] = React.useState("");

  const onSubmit = useCallback(
    async (e?: FormEvent) => {
      e?.preventDefault();
      setError("");
      const key = keyRef.current.value as string;
      const password = passwordRef.current.value as string;
      if (key && password) {
        window.localStorage.publicKey = key;
        window.localStorage.token = btoa(`${key}:${password}`);
        try {
          const response = await callApi("");
          if (response.message === "Success") {
            history.push("/portfolio");
            return;
          }
        } catch (e) {}
      }
      window.localStorage.token = undefined;
      setError("Incorrect key/password");
    },
    [history]
  );

  React.useEffect(() => {
    try {
      const query = new URLSearchParams(window.location.search);
      const token = query.get("token");
      if (token) {
        const s = atob(token);
        const [key, password] = s.split(":");
        if (key.startsWith("BC1")) {
          keyRef.current.value = key;
          passwordRef.current.value = password;
          onSubmit();
        }
      }
    } catch (e) {}
  }, [onSubmit]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {error && (
          <Typography component="h3" variant="h5">
            {error}
          </Typography>
        )}
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            inputRef={keyRef}
            required
            fullWidth
            id="key"
            placeholder="BC1...."
            label="Public Key"
            name="key"
            autoComplete="email"
            helperText={error}
            error={!!error}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            inputRef={passwordRef}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            error={!!error}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item>
              <Link
                href="https://bitclout.com/u/bitcloutsignal"
                variant="body2"
              >
                {
                  "Don't have an account? Check @bitcloutsignal for updates and invites"
                }
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
