import React, { memo, useState } from "react";
import { getSimpleLink, toPrecision } from "./config";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from "@material-ui/core";
/* This example requires Tailwind CSS v2.0+ */

export interface Header<T extends Row> {
  label: string;
  id: keyof T;
  sort?: boolean;
  type?: "number" | "percent" | "usd" | "user";
  colored?: boolean;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export interface Row {
  [id: string]: number | string;
}

interface Props<T extends Row> {
  rows: T[];
  headers: Header<T>[];
  defaultSortBy?: keyof T;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const MyTable = <T extends Row>({ headers, rows, defaultSortBy }: Props<T>) => {
  const [sortBy, setSortBy] = useState<keyof T | undefined>(defaultSortBy);
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const sorted = sortBy ? rows.sort(getComparator(order, sortBy)) : rows;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const headerClick = (id: keyof T) => {
    if (sortBy !== id) {
      setSortBy(id);
    } else {
      setOrder(order === "desc" ? "asc" : "desc");
    }
  };
  const getValue = (val: number | string, type: Header<T>["type"]) => {
    if (val === undefined) {
      return "";
    }
    if (type === "user" && typeof val === "string") {
      return (
        <span
          style={{
            maxWidth: 250,
            textOverflow: "ellipsis",
            display: "inline-block",
            overflow: "hidden",
          }}
        >
          {getSimpleLink(val)}
        </span>
      );
    }
    if (typeof val !== "number") {
      return val;
    }
    if (type === "usd") {
      return formatter.format(val);
    }
    if (type === "number") {
      return toPrecision(val);
    }
    if (type === "percent") {
      if (val === Infinity) {
        return "∞";
      }
      return val.toFixed(2) + "%";
    }
    return val;
  };
  return (
    <>
      <TableContainer style={{ maxHeight: "80vh" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((headCell) => (
                <TableCell
                  key={headCell.id as any}
                  align={
                    ["usd", "number", "percent"].includes(headCell.type as any)
                      ? "right"
                      : "left"
                  }
                  padding="default"
                  style={{ backgroundColor: "#eee" }}
                  sortDirection={sortBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={sortBy === headCell.id}
                    direction={sortBy === headCell.id ? order : "desc"}
                    onClick={() => headerClick(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((row, i) => (
                <TableRow hover tabIndex={-1} key={row.key || i}>
                  {headers.map((h) => (
                    <TableCell
                      key={i + (h.id as string)}
                      style={{
                        ...(h.colored
                          ? { color: row[h.id] >= 0 ? "green" : "red" }
                          : undefined),
                        textAlign: ["usd", "number", "percent"].includes(
                          h.type as any
                        )
                          ? "right"
                          : "left",
                      }}
                    >
                      {getValue(row[h.id], h.type)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        page={page}
        count={sorted.length}
        component="div"
        rowsPerPage={rowsPerPage}
        onChangePage={(_e, p) => setPage(p)}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPageOptions={[100, 1000, { value: 9999999, label: "All" }]}
      />
    </>
  );
};

export default memo(MyTable) as typeof MyTable;
