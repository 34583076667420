import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams, Link } from "react-router-dom";

import { Log, Profile } from "./types";
import {
  AppContext,
  callApi,
  getCoinPrice,
  getHistoryLink,
  toPrecision,
} from "./config";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";

interface Data {
  username: string;
  coins: number;
  in: number;
  out: number;
  sent: number;
  received: number;
  value: number;
  daysHold: number;
  diff: number;
  change: number;
  key: string;
  sellPrice: number;
  coinShare: number;
  circulation: number;
  locked: number;
  price: number;
  days: number;
}

interface LogExtended extends Log {
  _id: string;
  creatorUsername?: string;
  receiverUsername?: string;
  row: Data;
  usd: number;
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "username",
    numeric: false,
    disablePadding: true,
    label: "Username",
  },
  { id: "days", numeric: true, disablePadding: false, label: "Days HODL" },
  { id: "coins", numeric: true, disablePadding: false, label: "Coins" },
  {
    id: "received",
    numeric: false,
    disablePadding: false,
    label: "Transfers in/out",
  },
  { id: "in", numeric: true, disablePadding: false, label: "Spent" },
  { id: "out", numeric: true, disablePadding: false, label: "Sold" },
  { id: "value", numeric: true, disablePadding: false, label: "Value" },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Coin price",
  },
  { id: "diff", numeric: true, disablePadding: false, label: "Diff" },
  { id: "change", numeric: true, disablePadding: false, label: "Change %" },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "desc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    ellipsis: {
      maxWidth: 150,
      textOverflow: "ellipsis",
      display: "inline-block",
      overflow: "hidden",
    },
  })
);

export default function Portfolio() {
  const classes = useStyles();
  const [data, setData] =
    React.useState<{
      rows?: Data[];
      logs: LogExtended[];
      totalIn: number;
      totalOut: number;
      totalValue: number;
      initialInput: number;
      sold: number;
      limited: boolean;
      coinNanos: number;
      profile: Profile;
    }>();
  const [order, setOrder] = React.useState<Order>("desc");
  const [tabIndex, setTabIndex] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState<keyof Data>("diff");
  const history = useHistory();
  const [error, setError] = React.useState("");
  const [hideSmall, setHideSmall] = React.useState(true);
  const [showUSD, setShowUSD] = React.useState(true);
  const [showSold, setShowSold] = React.useState(true);
  const { usd, timestamp } = useContext(AppContext);
  const getUSD = (bitclout: number) =>
    showUSD ? formatter.format(usd * bitclout) : bitclout.toFixed(2);

  const { username } = useParams<any>();

  const fetchData = React.useCallback(async () => {
    const param = username || window.localStorage.publicKey;
    if (!param) {
      setError("You need to register to use our Portfolio Tool");
      return;
    }
    setError("");
    try {
      const response = await callApi("/portfolio/" + param);
      if (response?.rows) {
        const r = (response.rows as Data[]).map((d) => {
          const coins = d.coins < 0.00001 ? 0 : d.coins;
          const circulation = d.circulation;
          const price = getCoinPrice(d.locked, d.circulation);
          const sellPrice = getCoinPrice(
            d.locked - d.value,
            d.circulation - coins
          );

          return {
            ...d,
            coins,
            diff: d.value - d.in + d.out,
            change: ((d.value - d.in + d.out) / d.in) * 100,
            price,
            sellPrice,
            coinShare: circulation ? (coins / circulation) * 100 : 0,
            days: d.daysHold,
          };
        });
        const totalIn = r.reduce((t, r) => t + r.in, 0);
        const totalOut = r.reduce((t, r) => t + r.out, 0);
        const totalValue = r.reduce((t, r) => t + r.value, 0);
        setData({
          profile: response.profile,
          limited: response.limited,
          coinNanos: response.coinNanos,
          rows: r,
          totalIn,
          totalOut,
          totalValue,
          initialInput: response.initialInput,
          sold: response.sold,
          logs: response.logs.map((l: Log) => ({
            ...l,
            row: r.find((o) => o.key === l.creator),
          })),
        });
      }
    } catch (e) {
      if (e.message === "401") {
        history.replace("/login");
      } else if (e.message === "403") {
        if (!username) {
          setError(
            "We couldn't verify you are HODLing enough to view our portfolio tool. Make sure to HODL $500, ping @jensandersson if something is wrong."
          );
        } else {
          setError(
            "You only have access to your own data. Contact @bitcloutsignal if you want to see more 😏"
          );
        }
        return;
      } else if (e.message === "404") {
        setError("User/key not found");
        return;
      }
    }
  }, [username, history]);

  const rows = React.useMemo(() => {
    let r = data?.rows || [];
    return r
      .filter((r) => !hideSmall || (r.value || 0) + (r.out || 0) > 0.05)
      .filter((r) => showSold || r.coins > 0);
  }, [data?.rows, showSold, hideSmall]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  if (error) {
    return (
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    );
  }

  if (!data) {
    return <h2>Loading...</h2>;
  }

  const diff = data.totalValue + data.totalOut - data.totalIn;
  const realDiff = data.totalValue + data.sold - data.initialInput;
  const change = (diff / data.totalIn) * 100;
  const realChange = (realDiff / data.initialInput) * 100;
  return (
    <div className={classes.root}>
      <Paper className={classes.root}>
        <Tabs
          value={tabIndex}
          onChange={(e, v) => setTabIndex(v)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Portfolio" />
          <Tab label="Activity log" />
        </Tabs>
      </Paper>
      <Container maxWidth="lg">
        <Alert severity="info" style={{ margin: "40px 0" }}>
          Based on confirmed transactions, latest sync:{" "}
          {timestamp ? new Date(timestamp * 1000).toString() : "~1 h ago"}.
          Using ~$
          {usd.toFixed(2)} per coin. <br />
          <b>
            NOTE: All numbers are based on bitclout, ie there is no USD pricing
            history, if you spent in at $50 it will still say ${usd.toFixed(2)}
            ).
          </b>
        </Alert>
        {data && (
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            style={{ marginTop: 20 }}
          >
            {data.profile.username
              ? `@${data.profile.username}`
              : data.profile.key}
          </Typography>
        )}
      </Container>
      <Container maxWidth="lg" style={{ marginTop: 40, marginBottom: 40 }}>
        <Grid
          container
          spacing={4}
          style={{ textAlign: "center", marginBottom: 40 }}
        >
          <Grid item md={4} xs={12}>
            <Paper elevation={3} style={{ padding: "60px 0" }}>
              <Typography variant="body1">Investment</Typography>
              <Typography variant="h4">{getUSD(data.initialInput)}</Typography>
            </Paper>
          </Grid>
          <Grid item md={4} xs={12}>
            <Paper elevation={3} style={{ padding: "60px 0" }}>
              <Typography variant="body1">ROI (Holding + Sold)</Typography>
              <Typography variant="h4">
                {getUSD(data.totalValue + data.sold)}
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={4} xs={12}>
            <Paper elevation={3} style={{ padding: "60px 0" }}>
              <Typography variant="body1">Growth</Typography>
              <Typography variant="h4">{realChange.toFixed(0)}%</Typography>
            </Paper>
          </Grid>
        </Grid>
        {data.limited && (
          <Alert variant="filled" severity="error">
            To see your performance on a coin level you need to HODL $500 of
            @bitcloutsignal's coin
          </Alert>
        )}

        {!data.limited && (
          <div>
            <div style={{ textAlign: "right" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!showSold}
                    onChange={(e) => setShowSold(!e.target.checked)}
                    name="showSold"
                  />
                }
                label="Hide sold coins"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={hideSmall}
                    onChange={(e) => setHideSmall(e.target.checked)}
                    name="hideSmall"
                  />
                }
                label="Hide small amounts"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={showUSD}
                    onChange={(e) => setShowUSD(e.target.checked)}
                    name="showUSD"
                  />
                }
                label="Toggle USD/Bitclout"
              />
            </div>
            {tabIndex === 0 && (
              <div>
                <TableContainer style={{ maxHeight: "80vh" }}>
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={"small"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy)).map(
                        (row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow hover tabIndex={-1} key={row.key}>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {getHistoryLink(row.username, row.key)}
                              </TableCell>
                              <TableCell align="right">
                                {row.days.toFixed(1)}
                              </TableCell>
                              <TableCell align="right">
                                {row.coins.toFixed(2)} (
                                {row.coinShare.toFixed(1)}
                                %)
                              </TableCell>
                              <TableCell align="right">
                                +{toPrecision(row.received)} / -
                                {toPrecision(row.sent)}
                              </TableCell>
                              <TableCell align="right">
                                {getUSD(row.in)}
                              </TableCell>
                              <TableCell align="right">
                                {getUSD(row.out)}
                              </TableCell>
                              <TableCell align="right">
                                {getUSD(row.value)}
                              </TableCell>
                              <TableCell align="right">
                                {getUSD(row.price)} /{" "}
                                {row.coins === 0 ? "-" : getUSD(row.sellPrice)}
                              </TableCell>
                              <TableCell align="right">
                                {getUSD(row.diff)}
                              </TableCell>
                              <TableCell align="right">
                                {row.change.toFixed(1)}%
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                      <TableRow hover tabIndex={-1}>
                        <TableCell
                          component="th"
                          colSpan={3}
                          scope="row"
                          padding="none"
                        >
                          <b>Total (incl. reinvestments)</b>
                        </TableCell>
                        <TableCell align="right">
                          {getUSD(data.totalIn)}
                        </TableCell>
                        <TableCell align="right">
                          {getUSD(data.totalOut)}
                        </TableCell>
                        <TableCell align="right">
                          {getUSD(data.totalValue)}
                        </TableCell>
                        <TableCell align="right">N/A</TableCell>
                        <TableCell align="right">{getUSD(diff)}</TableCell>
                        <TableCell align="right">
                          {change.toFixed(1)}%
                        </TableCell>
                      </TableRow>
                      <TableRow hover tabIndex={-1}>
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={3}
                          padding="none"
                        >
                          <b>Total (excl. reinvestments)</b>
                        </TableCell>
                        <TableCell align="right">
                          {getUSD(data.initialInput)}
                        </TableCell>
                        <TableCell align="right">{getUSD(data.sold)}</TableCell>
                        <TableCell align="right">
                          {getUSD(data.totalValue)}
                        </TableCell>
                        <TableCell align="right">N/A</TableCell>
                        <TableCell align="right">{getUSD(realDiff)}</TableCell>
                        <TableCell align="right">
                          {realChange.toFixed(1)}%
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  container
                  spacing={4}
                  style={{ marginTop: 40, marginBottom: 40 }}
                >
                  <Grid item md={6}>
                    <p>
                      This analysis will improve as we go.
                      <br />
                      <b>Days HODL:</b> average days HOLDing the coin
                      <br />
                      <b>Spent:</b> how much you've put in (including
                      re-investing)
                      <br />
                      <b>Sold:</b> how much you've sold
                      <br />
                      <b>Value:</b> your current hold, ie coins held x sell
                      price
                      <br />
                      <b>Coin price:</b> current coin price / coin price after
                      you sell
                      <br />
                      <b>Diff:</b> sold + current value - spent
                      <br />
                      <b>Change:</b> diff / spent
                      <br />
                    </p>
                  </Grid>
                  <Grid item md={6}>
                    <p>
                      You can also view the history of your own coin:
                      <br />
                      <br />
                      <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to="/history"
                      >
                        Coin history
                      </Button>
                    </p>
                  </Grid>
                </Grid>
              </div>
            )}
            {tabIndex === 1 && (
              <TableContainer style={{ maxHeight: "80vh" }}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="sticky simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>Creator</TableCell>
                      <TableCell align="right">Coins</TableCell>
                      <TableCell align="right">$bclt</TableCell>
                      <TableCell align="right">USD (historic)</TableCell>
                      <TableCell align="right">Coin Price</TableCell>
                      <TableCell align="right">Current Coin price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.logs
                      .filter((l) => l.action !== "transfer")
                      .map((log) => (
                        <TableRow key={log._id}>
                          <TableCell>
                            {log.timestamp
                              ? moment(log.timestamp * 1000).format(
                                  "YYYY-MM-DD HH:mm"
                                )
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {
                              {
                                buy: "Bought 📈",
                                sell: "Sold 📉",
                                transfer:
                                  log.transactor === data.profile.key
                                    ? "Sent ➡️"
                                    : "Received ⬅️",
                              }[log.action]
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className={classes.ellipsis}>
                              {log.creator &&
                                getHistoryLink(
                                  log.creatorUsername,
                                  log.creator
                                )}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            {toPrecision(
                              Math.abs((log.coins || 0) - (log.fr || 0))
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {log.bitclouts && toPrecision(log.bitclouts, 2)}
                          </TableCell>
                          <TableCell align="right">
                            {log.usd && formatter.format(log.usd)}
                          </TableCell>
                          <TableCell align="right">
                            ~
                            {log.usd &&
                              log.coins &&
                              formatter.format(Math.abs(log.usd / log.coins))}
                          </TableCell>
                          <TableCell align="right">
                            ~{log.row && getUSD(log.row.price)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        )}
      </Container>
    </div>
  );
}
