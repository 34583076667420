import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Portfolio from "./Portfolio";
import History from "./History";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import VerifyPost from "./VerifyPost";
import { apiUrl, AppContext, getPrice } from "./config";
import { useCallback, useEffect, useState } from "react";
import Toplist from "./Toplist";
import RugPulls from "./RugPulls";
import NewHome from "./NewHome";
import Pricing from "./Pricing";
import TopTransfers from "./TopTransfers";
import Transfers from "./Transfers";
import Stats from "./Stats";
import Navigation from "./Navigation";
import TopCreators from "./TopCreators";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#1B233C",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#1B233C",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: '"Roboto Mono", monospace',
  },
});

export default function App() {
  const [state, setState] = useState({
    usd: parseFloat(localStorage.bitcloutUsd || 150),
    timestamp: 0,
  });
  const fetchStuff = useCallback(async () => {
    const usd = await getPrice();
    localStorage.bitcloutUsd = usd;
    const { timestamp } = await fetch(apiUrl + "/public/latest").then((r) =>
      r.json()
    );
    setState({ usd, timestamp });
  }, []);
  useEffect(() => {
    fetchStuff();
  }, [fetchStuff]);
  return (
    <AppContext.Provider value={state}>
      <ThemeProvider theme={theme}>
        <Router>
          <Navigation>
            <div>
              <Switch>
                <Route path="/login">
                  <SignIn />
                </Route>
                <Route path="/verify">
                  <VerifyPost />
                </Route>
                <Route path="/register">
                  <SignUp />
                </Route>
                <Route path="/stats">
                  <Stats />
                </Route>
                <Route path="/portfolio/:username">
                  <Portfolio />
                </Route>
                <Route path="/portfolio">
                  <Portfolio />
                </Route>
                {/* <Route path="/diamonds">
                  <Diamonds />
                </Route> */}
                <Route path="/top-creators">
                  <TopCreators />
                </Route>
                <Route path="/top-transactions">
                  <Toplist />
                </Route>
                <Route path="/transfers/:username">
                  <Transfers />
                </Route>
                <Route path="/top-transfers">
                  <TopTransfers />
                </Route>
                <Route path="/rug-pulls">
                  <RugPulls />
                </Route>
                <Route path="/history/:username?">
                  <History />
                </Route>
                <Route path="/pricing">
                  <Pricing />
                </Route>
                <Route path="/">
                  <NewHome />
                </Route>
              </Switch>
            </div>
          </Navigation>
        </Router>
      </ThemeProvider>
    </AppContext.Provider>
  );
}
