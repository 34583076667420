import moment from "moment";
import React from "react";
import BarChart from "./BarChart";
import { apiUrl } from "./config";
import MyTable, { Header } from "./MyTable";

const Pricing = () => {
  const [days, setDays] = React.useState([]);
  const fetchStuff = React.useCallback(async () => {
    const data = await fetch(apiUrl + "/public/pricing").then((r) => r.json());
    setDays(data.days);
  }, []);
  React.useEffect(() => {
    fetchStuff();
  }, [fetchStuff]);

  const rows = days.map((d: any) => ({
    day: d.day,
    price: (877 * Math.pow(2, d.bitclout / 1000000) * d.btcUsd) / 100000000,
  }));

  const data = days.map((d: any) => [
    moment.utc(d.day).format("YYYY-MM-DD"),
    (877 * Math.pow(2, d.bitclout / 1000000) * d.btcUsd) / 100000000,
  ]);

  const headers: Header<typeof rows[0]>[] = [
    {
      label: "Day",
      id: "day",
    },
    {
      label: "Price",
      id: "price",
      type: "usd",
    },
  ];

  const graphData = {
    type: "bar" as "bar",
    data: {
      labels: data.map((d: any) => d[0]) as any,
      datasets: [
        {
          label: "BitClout Price in USD",
          data: data.map((d: any) => d[1]) as number[],
          borderWidth: 1,
        },
      ],
    },
    options: {
      scales: {
        x: {
          type: "time",
          time: {
            unit: "day",
          },
        } as any,
      },
    },
  };
  return (
    <>
      <div
        style={{
          maxWidth: "90%",
          width: 1000,
          margin: "40px auto",
        }}
      >
        {data.length && <BarChart data={graphData} />}
      </div>
      <div style={{ maxWidth: 600, margin: "auto" }}>
        <MyTable headers={headers} rows={rows} />
      </div>
    </>
  );
};

export default React.memo(Pricing);
